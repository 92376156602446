export const theme = {
  light: {
    main: '#fbfcfa',
    text: '#14110f',
  },
  dark: {
    main: '#14110f',
    text: '#fbfcfa',
  },
};
